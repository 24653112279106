import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {AuthenticationService} from '../../core/services/authentication.service';
import {LanguageService} from '../../core/services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../shared/data/model/user.model';
import {flatMap, switchMap} from 'rxjs/operators';
import {UserRestService} from '../../shared/data/service/user-rest.service';
import {interval, of} from 'rxjs';
import {ClientRestService} from '../../shared/data/service/client-rest.service';
import {Client} from '../../shared/data/model/client.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  activeUser: User;
  currentCompany: Client;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authenticationService: AuthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    private userRestService: UserRestService,
    private clientRestService: ClientRestService,
  ) {}

  listLang = [
    {text: 'Polish', flag: 'assets/images/flags/pl.jpg', lang: 'pl'},
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'Ukrainian', flag: 'assets/images/flags/ukr.jpg', lang: 'ukr'},
    {text: 'Italian', flag: 'assets/images/flags/it.jpg', lang: 'it'},
  ];

  openMobileMenu: boolean;

  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    interval(600000)
      .pipe(flatMap(data => this.authenticationService.refresh()))
      .subscribe();

    this.clientRestService.findActiveClient().subscribe(activeClient => {
      this.currentCompany = activeClient;
    });

    this.authenticationService.currentUser
      .pipe(
        switchMap(loggedUser => {
          if (loggedUser?.id === this.activeUser?.id) {
            return of(this.activeUser);
          }
          if (loggedUser?.id !== undefined && loggedUser?.id !== null) {
            return this.userRestService.find(loggedUser.id);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(user => {
        this.activeUser = user;
      });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };

    this.cookieValue = localStorage.getItem('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/pl.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.authenticationService.fullLogout();
  }

  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  createInitials(): string {
    return this.activeUser ? this.activeUser.firstname.charAt(0) + this.activeUser.surname.charAt(0) : '';
  }
}
