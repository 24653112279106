export const ADD_BASE_INSTRUCTION = '/Instruction/addBaseInstruction';
export const ARCHIVE_INSTRUCTION = '/Instruction/archiveInstruction';
export const UPDATE_INSTRUCTION = '/Instruction/updateInstruction';
export const GET_INSTRUCTION = '/Instruction/getInstruction';
export const GET_MAIN_INSTRUCTIONS = '/Instruction/getMainInstructions';
export const DELETE_INSTRUCTION = '/Instruction/removeInstruction';
export const GET_BASE_INSTRUCTIONS = '/Instruction/getBaseInstructions';
export const GET_INSTRUCTION_VERIFICATIONS = '/Instruction/getInstructionVerificationStatus';
export const SAVE_INSTRUCTION_VERIFICATIONS = '/Instruction/updateInstructionVerificationStatus';
export const SAVE_INSTRUCTION_VERIFICATIONS_STATUS = '/Instruction/updateInstructionVerificationStatusNew';
export const UPDATE_INSTRUCTION_IMAGE = '/InstructionImage/UpdateInstructionImage';
export const REMIND_ABOUT_VERIFICATION = '/Instruction/remindAboutVerification';
export const GET_INSTRUCTION_VERIFICATION_STATUS_FOR_USER = '/Instruction/getInstructionVerificationStatusForUser';
export const GET_INSTRUCTION_PASSPORT = '/Instruction/getInstructionPass';

export const GET_INSTRUCTION_REASON_SYMBOLS = '/Dictionary/getReasonSymbol';
export const GET_INSTRUCTION_VERIFICATION_STATUSES = '/Dictionary/getVerificationStatus';
export const GET_INSTRUCTION_STATUSES = '/Dictionary/getInstructionStatus';
export const GET_INSTRUCTION_TIME_UNITS = '/Dictionary/getTimeUnit';
export const GET_NODE_TYPES = '/Dictionary/getNodeType';

export const GET_DEPARTMENTS = '/Department/getDepartments';
export const UPDATE_DEPARTMENTS = '/Department/updateDepartments';
export const ADD_DEPARTMENT = '/Department/addDepartment';
export const ADD_AREA = '/Department/addArea';
export const ADD_WORKSTATION = '/Department/addWorkStation';

export const GET_VERIFICATION_DEPARTMENTS = '/Department/getVerificationDepartments';
export const UPDATE_VERIFICATION_DEPARTMENT = '/Department/updateVerificationDepartment';
export const DELETE_VERIFICATION_DEPARTMENT = '/Department/removeVerificationDepartment';
export const ADD_VERIFICATION_DEPARTMENT = '/Department/addVerificationDepartment';

export const GET_COMPANY_TREE_MENU = '/Company/getCompanyTreeMenu';

export const GET_CLIENTS = '/Client/GetClientList';
export const GET_CLIENT_SMTP = '/Client/GetSmtpServerCredentials';
export const UPDATE_CLIENT_SMTP = '/Client/UpdateSmtpServerCredentials';
export const GET_CLIENT_EMAIL = '/Client/GetMailDataConfiguration';
export const UPDATE_CLIENT_EMAIL = '/Client/UpdateMailDataConfiguration';
export const UPDATE_PASSWORD_SMTP = '/Client/UpdateSmtpServerPassword';
export const ADD_CLIENT = '/Client/AddClient';
export const UPDATE_CLIENT = '/Client/EditClient';

export const UPDATE_USER_PASSWORD = '/User/changePassword';
export const GET_MAIL_LIST = '/User/getMailList';
export const GET_USERS = '/User/getUsers';
export const GET_USER = '/User/getUser';
export const ADD_USER = '/User/addUser';
export const UPDATE_USER = '/User/updateUser';
export const REMOVE_USER = '/User/removeUser';
export const RESET_PASSWORD = '/User/resetPassword';
export const CHECK_TOKEN = '/User/checkToken';
export const CHECK_CONFIRM_TOKEN = '/User/checkConfirmToken';
export const UPDATE_PASSWORD = '/User/setNewPassword';
export const CONFIRM_EMAIL = '/User/confirmEmail';

export const GET_ROLES = '/User/getRole';
export const GET_ACCOUNT_TYPES = '/User/accountTypesGet';
export const UPDATE_ACCOUNT_TYPE = '/User/updateAccountType';
export const ADD_ACCOUNT_TYPE = '/User/addAccountType';
export const REMOVE_ACCOUNT_TYPE = '/User/removeAccountType';

export const GET_SYSTEM_CONFIGURATION = '/Configuration/getSystemConfiguration';
export const UPDATE_SYSTEM_CONFIGURATION = '/Configuration/updateSystemConfiguration';
export const GET_LANGUAGES = '/Configuration/getGetLanguageList';
export const GET_SYSTEM_PARAMETER_TYPES = '/Configuration/getSystemParameterType';
export const GET_SYSTEM_CONFIGURATION_DATES = '/Configuration/getSystemConfigurationDates';
export const GET_NUMBER_TYPE = '/Configuration/getNumberType';

export const UPDATE_PREDEFINED_STEP = '/BasicStep/updateFullDBStep';
export const GET_PREDEFINED_STEP = '/BasicStep/getDBStep';
export const GET_PREDEFINED_STEPS = '/BasicStep/getDBSteps';
export const DELETE_PREDEFINED_STEP = '/BasicStep/removeStep';

export const GET_MAIL_TEMPLATE_TYPE = '/MailTemplate/getMailTemplateType';
export const GET_MAIL_TEMPLATE_PARAMETER = '/MailTemplate/getMailTemplateParameter';
export const GET_MAIL_TEMPLATE = '/MailTemplate/getMailTemplate';
export const UPDATE_MAIL_TEMPLATE = '/MailTemplate/updateMailTemplate';

export const GET_ADMIN_DASHBOARD_DATA = '/DashBoard/getAdminDashBoard';
export const GET_AUTHOR_DASHBOARD_DATA = '/DashBoard/getAuthorDashBoard';
export const GET_VERIFIER_DASHBOARD_DATA = '/DashBoard/getVerifierDashBoard';
export const GET_TRAINER_DASHBOARD_DATA = '/DashBoard/getTrainerDashBoard';

export const CATEGORIES = '/Categories';

export const PICTOGRAMS = '/Pictograms';

export const GET_PICTOGRAMS_SETS = '/Pictograms/GetPictogramSets';

export const GET_PICTOGRAMS_SET = '/Pictograms/GetPictogramSet';
export const DELETE_PICTOGRAMS_SET = '/Pictograms/DeletePictogramSet';
export const ADD_PICTOGRAMS_SET = '/Pictograms/AddPictogramSet';
export const UPDATE_PICTOGRAMS_SET = '/Pictograms/UpdatePictogramSet';

export const GET_PICTOGRAM_CATEGORIES = '/Pictograms/GetPictogramCategories';
export const GET_PICTOGRAM_CATEGORY = '/Pictograms/GetPictogramCategory';
export const DELETE_PICTOGRAM_CATEGORY = '/Pictograms/DeletePictogramCategory';
export const ADD_PICTOGRAM_CATEGORY = '/Pictograms/AddPictogramCategory';
export const UPDATE_PICTOGRAM_CATEGORY = '/Pictograms/UpdatePictogramCategory';

export const ADD_PICTOGRAM = '/Pictograms/AddPictogram';
export const GET_PICTOGRAM = '/Pictograms/GetPictogram';
export const DELETE_PICTOGRAM = '/Pictograms/DeletePictogram';
export const UPDATE_PICTOGRAM = '/Pictograms/UpdatePictogram';




export const ADD_SYMBOL = '/Categories/AddSymbol';
export const DELETE_SYMBOL = '/Categories/DeleteSymbol';

export const SYSTEM_VERSION = '/System/GetVersion';
