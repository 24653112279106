import {Component, OnInit} from '@angular/core';
import {Client} from 'src/app/shared/data/model/client.model';
import {ClientRestService} from 'src/app/shared/data/service/client-rest.service';
import {SystemVersionRestService} from '../../shared/data/service/system-version-rest.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();

  client$: Observable<Client>;
  version$: Observable<string>;

  constructor(private clientRestService: ClientRestService, private systemVersionRestService: SystemVersionRestService) {}

  ngOnInit() {
    this.client$ = this.clientRestService.findActiveClient();
    this.version$ = this.systemVersionRestService.getSystemVersion();
  }
}
