import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

import {AccountTypeRestService} from '../../shared/data/service/account-type-rest.service';
import {JwtUser, LoggedUser} from '../../shared/data/model/logged-user.models';
import {LOGIN_URL, LOGOUT_URL, REFRESH_TOKEN_URL, url} from '../../shared/data/server/rest.util';
import {Response} from '../../shared/data/server/response.model';
import {
  CHECK_CONFIRM_TOKEN,
  CHECK_TOKEN,
  CONFIRM_EMAIL,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
} from 'src/app/shared/data/server/rest-endpoint.constant';

export interface JwtResult {
  accessToken: string;
  refreshToken: string;
  username: string;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoggedUser>;
  public currentUser: Observable<LoggedUser>;

  constructor(private http: HttpClient, private accountTypeRestService: AccountTypeRestService) {
    this.currentUserSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoggedUser {
    return this.currentUserSubject.value;
  }

  refresh(): Observable<LoggedUser> {
    return this.updateCurrentUser(
      this.http.post<Response<JwtResult>>(REFRESH_TOKEN_URL, {refreshToken: this.currentUserSubject.getValue().refreshToken}),
    );
  }

  login(username: string, password: string): Observable<LoggedUser> {
    return this.updateCurrentUser(
      this.http.post<Response<JwtResult>>(LOGIN_URL, {username, password}),
    );
  }

  resetPassword(email: string): Observable<Response<boolean>> {
    const params = new HttpParams().set('email', email);
    return this.http.post<Response<boolean>>(url(`${RESET_PASSWORD}?${params}`), {});
  }

  checkToken(token: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(url(`${CHECK_TOKEN}`), {token});
  }

  checkConfirmToken(token: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(url(`${CHECK_CONFIRM_TOKEN}`), {token});
  }

  updatePassword(token: string, newPassword: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(url(UPDATE_PASSWORD), {token, newPassword});
  }

  confirmEmail(token: string, newPassword: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(url(CONFIRM_EMAIL), {token, newPassword});
  }

  private updateCurrentUser(jwtResponse: Observable<Response<JwtResult>>): Observable<LoggedUser> {
    return jwtResponse.pipe(
      switchMap(response => {
        if (response?.statusCode === 200 && response?.result?.accessToken) {
          return this.mapToUser(response.result.accessToken, response.result.refreshToken);
        } else {
          return throwError("Couldn't authenticate user");
        }
      }),
      map(user => {
        if (user) {
          this.addUserToCache(user);
        }
        return user;
      }),
    );
  }

  fullLogout(): void {
    this.http.post<Response<boolean>>(LOGOUT_URL, {}).subscribe(response => {
      this.logout();
    });
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

    location.reload();
  }

  private mapToUser(token: string, refreshToken: string): Observable<LoggedUser> {
    const decodedToken: JwtUser = jwt_decode(token);
    const user: LoggedUser = {
      id: Number(decodedToken.UserId),
      firstname: decodedToken.Firstname,
      surname: decodedToken.LastName,
      token,
      refreshToken,
    };
    this.addUserToCache(user);

    return this.accountTypeRestService.findAllAccountTypes().pipe(
      map(accountTypes => {
        user.accountType = accountTypes.filter(accountType => accountType.id === Number(decodedToken.AccountTypeId))[0];
        return user;
      }),
    );
  }

  private addUserToCache(user: LoggedUser): void {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }
}
